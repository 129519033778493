// import "@bam-spark/nav-toggle";
require('waypoints/lib/noframework.waypoints.js');
require('headroom.js/dist/headroom.min.js');

import Headroom from "headroom.js/dist/headroom.min.js";
const header = document.querySelector(".c-header__wrapper");
var options = {
    offset : 39,
}

const headroom = new Headroom(header, options);
headroom.init();

const toggles = document.querySelectorAll('.js-toggle');

for (let a of toggles) {
    a.addEventListener('click', function (event) {

      if (a.classList.contains('utl-is-expanded')) {
        a.classList.remove('utl-is-expanded');
        a.setAttribute('aria-expanded', 'false');
        event.preventDefault();

      } else {
        a.classList.add('utl-is-expanded');
        a.setAttribute('aria-expanded', 'true');
        event.preventDefault();
      }
    });
}

var elements = document.getElementsByClassName('js-animate');

for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    element.className += ' js-animate--pending';
}

var continuousElements = document.getElementsByClassName('js-animate')
for (var i = 0; i < continuousElements.length; i++) {
  new Waypoint({
    element: continuousElements[i],
    handler: function() {
      this.element.classList.add("js-animate--start")
  },
    offset: '90%'
  })
}

var lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy"
});


document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });


    $(function() {

    var lastId,
        topMenu = $(".c-nav--primary"),
        topMenuHeight = topMenu.outerHeight()+15,
        menuItems = topMenu.find('a[href^="#"]'),
        scrollItems = menuItems.map(function(){
          var item = $($(this).attr("href"));
          if (item.length) { return item; }
       });


    // menuItems.click(function(e){
    //
    //   var href = $(this).attr("href"),
    //
    //       offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight-150;
    //
    //       event.preventDefault();
    //         $('html, body').stop(true, true).animate({
    //           scrollTop: $(window).scrollTop() + offsetTop
    //         }, 1000
    //     );
    // });
    // Bind to scroll
    $(window).scroll(function(){
       // Get container scroll position
       var fromTop = $(this).scrollTop()+topMenuHeight;
       // Get id of current scroll item
       var cur = scrollItems.map(function(){
         if ($(this).offset().top < fromTop)
           return this;
       });
       // Get the id of the current element
       cur = cur[cur.length-1];
       var id = cur && cur.length ? cur[0].id : "";
       if (lastId !== id) {
           lastId = id;
           // Set/remove active class
           menuItems
             .parent().removeClass("utl-is-active")
             .end().filter("[href='#"+id+"']").parent().addClass("utl-is-active");
       }
});
});
})
